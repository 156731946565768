import React from "react";
import "./germanygate.css";
import Footer from "../../footer/footer";
import { Divider } from "primereact/divider";
import { DataView } from "primereact/dataview";
export default function GermanyGate() {
  const NewGermanyGates = [
    {
      code: "588RS",
      name: "588RS",
      image: "germanygates/1_588RS.png",
      category: "Cửa cuốn Đức",
      price: "2.710.000",
    },
    {
      code: "589RS",
      name: "589RS",
      image: "germanygates/2_589RS.png",
      category: "Cửa cuốn Đức",
      price: "2.916.000",
    },
    {
      code: "5810RS",
      name: "5810RS",
      image: "germanygates/3_5810RS.png",
      category: "Cửa cuốn Đức",
      price: "3.178.000",
    },
    {
      code: "5811RS",
      name: "5811RS",
      image: "germanygates/4_5811RS.png",
      category: "Cửa cuốn Đức",
      price: "3.480.000",
    },
    {
      code: "557R",
      name: "557R",
      image: "germanygates/5_557R.png",
      category: "Cửa cuốn Đức",
      price: "2.730.000",
    },
    {
      code: "558RS",
      name: "558RS",
      image: "germanygates/6_558RS.png",
      category: "Cửa cuốn Đức",
      price: "2.830.000",
    },
    {
      code: "701RS",
      name: "701RS",
      image: "germanygates/7_701RS.png",
      category: "Cửa cuốn Đức",
      price: "2.625.000",
    },
    {
      code: "559R",
      name: "559R",
      image: "germanygates/8_559R.png",
      category: "Cửa cuốn Đức",
      price: "2.865.000",
    },
    {
      code: "5510R",
      name: "5510R",
      image: "germanygates/9_5510R.png",
      category: "Cửa cuốn Đức",
      price: "3.115.000",
    },
    {
      code: "5511R",
      name: "5511R",
      image: "germanygates/10_5511R.png",
      category: "Cửa cuốn Đức",
      price: "3.460.000",
    },
    {
      code: "515A",
      name: "515A",
      image: "germanygates/11_515A.png",
      category: "Cửa cuốn Đức",
      price: "3.625.000",
    },
    {
      code: "525A",
      name: "525A",
      image: "germanygates/12_525A.png",
      category: "Cửa cuốn Đức",
      price: "4.042.000",
    },
  ];
  const EconomyGermanyGates = [
    {
      code: "070HE",
      name: "070HE",
      image: "germanygates/13_070HE.png",
      category: "Cửa cuốn Đức",
      price: "1.695.000",
    },
    {
      code: "718HE",
      name: "718HE",
      image: "germanygates/14_718HE.png",
      category: "Cửa cuốn Đức",
      price: "1.805.000",
    },
    {
      code: "518HE",
      name: "518HE",
      image: "germanygates/15_518HE.png",
      category: "Cửa cuốn Đức",
      price: "2.040.000",
    },
    {
      code: "528HE",
      name: "528HE",
      image: "germanygates/16_528HE.png",
      category: "Cửa cuốn Đức",
      price: "2.205.000",
    },
    {
      code: "541HE",
      name: "541HE",
      image: "germanygates/17_541HE.png",
      category: "Cửa cuốn Đức",
      price: "2.580.000",
    },
    {
      code: "HNDa",
      name: "HNDa",
      image: "germanygates/18_HNDa.png",
      category: "Cửa cuốn Đức",
      price: "2.725.000",
    },
  ];
  const LuxuryGermanyGates = [
    {
      code: "508HS",
      name: "508HS",
      image: "germanygates/19_508HS.png",
      category: "Cửa cuốn Đức",
      price: "2.390.000",
    },
    {
      code: "5222HS",
      name: "5222HS",
      image: "germanygates/20_5222HS.png",
      category: "Cửa cuốn Đức",
      price: "3.295.000",
    },
    {
      code: "5223HS",
      name: "5223HS",
      image: "germanygates/21_5223HS.png",
      category: "Cửa cuốn Đức",
      price: "3.605.000",
    },
    {
      code: "5224HP",
      name: "5224HP",
      image: "germanygates/22_5224HP.png",
      category: "Cửa cuốn Đức",
      price: "3.805.000",
    },
    {
      code: "5225HP",
      name: "5225HP",
      image: "germanygates/23_5225HP.png",
      category: "Cửa cuốn Đức",
      price: "4.040.000",
    },
    {
      code: "5227HP",
      name: "5227HP",
      image: "germanygates/24_5227HP.png",
      category: "Cửa cuốn Đức",
      price: "4.510.000",
    },
    {
      code: "5442HL",
      name: "5442HL",
      image: "germanygates/25_H442HL.png",
      category: "Cửa cuốn Đức",
      price: "3.360.000",
    },
    {
      code: "5446HL",
      name: "5446HL",
      image: "germanygates/26_5446HL.png",
      category: "Cửa cuốn Đức",
      price: "4.350.000",
    },
    {
      code: "71HC",
      name: "71HC",
      image: "germanygates/27_71HC.png",
      category: "Cửa cuốn Đức",
      price: "2.900.000",
    },
    {
      code: "110HD",
      name: "110HD",
      image: "germanygates/28_110HD.png",
      category: "Cửa cuốn Đức",
      price: "2.990.000",
    },
  ];
  const itemTemplate = (product) => {
    if (!product) {
      return;
    }
    return gridItem(product);
  };
  const listTemplate = (products) => {
    return (
      <div className="row">
        {products.map((product, index) => itemTemplate(product, index))}
      </div>
    );
  };
  const gridItem = (product) => {
    return (
      <div className=" card responsive_grid_item col-4" key={product.code}>
        <a
          style={{ textDecoration: "none" }}
          href={`/san-pham/${product.code}`}
        >
          <div className="p-4">
            <div className="d-flex justify-content-center">
              <img
                style={{ width: "100%", height: "300px" }}
                src={`/${product.image}`}
                alt={product.name}
              />
            </div>
            <div className="row">
              <div className="col"></div>
              <div
                className="d-flex justify-content-center col-7 text-2xl font-bold"
                style={{ color: "#1d4794", fontWeight: "bold" }}
              >
                {product.name}
              </div>
              <div className="col"></div>
              <span style={{ color: "red" }}>
                {`${product.price}`} VNĐ/m<sup>2</sup>
              </span>
            </div>
          </div>
        </a>
      </div>
    );
  };
  return (
    <>
      <div>
        <div className=" d-flex justify-content-center">
          <b style={{ fontSize: "36px", color: "red" }}>Cửa Đức</b>
        </div>

        <Divider align="center">
          <div className="inline-flex align-items-center">
            <b style={{ fontSize: "20px", color: "#1d4794" }}>
              Dòng sản phẩm Hitadoor thế hệ mới{" "}
            </b>
          </div>
        </Divider>
        <DataView
          className="container"
          value={NewGermanyGates}
          listTemplate={listTemplate}
        />

        <Divider align="center">
          <div className="inline-flex align-items-center">
            <b style={{ fontSize: "20px", color: "#1d4794" }}>
              Dòng sản phẩm Hitadoor Economy{" "}
            </b>
          </div>
        </Divider>
        <DataView
          className="container"
          value={EconomyGermanyGates}
          listTemplate={listTemplate}
        />
        <Divider align="center">
          <div className="inline-flex align-items-center">
            <b style={{ fontSize: "20px", color: "#1d4794" }}>
              Dòng sản phẩm Hitadoor Luxury{" "}
            </b>
          </div>
        </Divider>
        <DataView
          className="container"
          value={LuxuryGermanyGates}
          listTemplate={listTemplate}
        />
      </div>
      <Footer />
    </>
  );
}
