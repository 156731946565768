import React from "react";
import Footer from "./../footer/footer";
import { DataView } from "primereact/dataview";
export default function news() {
  const NewsData = [
    {
      code: "hitadoor-khai-truong-showroom-tai-binh-duong",
      name: "Hitadoor Khai Trương Showroom",
      name2: "Mới Vào Tháng 3, năm 2022",
      image: "tintuc/khaitruong/1.jpg",
    },
    {
      code: "hitadoor-dat-top-100-thuong-hieu-chau-a-thai-binh-duong",
      name: "Hitadoor Đạt Top 100 Thương Hiệu ",
      name2: "Châu Á - Thái Bình Dương Vào Ngày 22/10/2022",
      image: "tintuc/top100/2.jpg",
    },
    {
      code: "hitadoor-dat-top-50-thuong-hieu-manh-quoc-gia",
      name: "Hitadoor -Top 50 ",
      name2: "Thương hiệu mạnh quốc gia",
      image: "tintuc/top50/3.png",
    },
    {
      code: "hitadoor-nha-tai-tro-vang-tai-le-trung-bay-va-ra-mat-hiep-hoi-cua-viet-nam-chi-hoi-thanh-pho-ho-chi-minh",
      name: "Hitadoor - Nhà tài trợ vàng tại Lễ Trưng Bày & Ra Mắt Hiệp  ",
      name2:
        " Hội Cửa Việt Nam- Chi Hội Thành Phố Hồ Chí Minh vào ngày 18-19/05/2024",
      image: "tintuc/letrungbay/4.jpg",
    },
    {
      code: "hitadoor-tham-gia-trien-lam-trung-bay-cua-cua-hiep-hoi-cua-viet-nam-chi-hoi-thanh-pho-can-tho",
      name: "Hitadoor Tham Gia Triển Lãm Trưng Bày Cửa Của ",
      name2: " Hiệp Hội Cửa Việt Nam - Chi Hội TP Cần Thơ vào ngày 19-20/07/2024",
      image: "tintuc/hiephoi/5.png",
    },
  ];
  const itemTemplate = (product) => {
    if (!product) {
      return;
    }
    return gridItem(product);
  };
  const listTemplate = (products) => {
    return (
      <div className="row">
        {products.map((product, index) => itemTemplate(product, index))}
      </div>
    );
  };
  const gridItem = (product) => {
    return (
      <div className="card responsive_grid_item col-6" key={product.code}>
        <a
          style={{ textDecoration: "none" }}
          href={`/tin-tuc-su-kien/${product.code}`}
        >
          <div className="p-4">
            <div className="row">
              <div
                className=" text-2xl font-bold d-flex justify-content-center"
                style={{
                  color: "#1d4794",
                  fontWeight: "bold",
                }}
              >
                 {product.name}
              </div>
              <div
                className=" text-2xl font-bold d-flex justify-content-center"
                style={{
                  color: "#1d4794",
                  fontWeight: "bold",
                }}
              >
                {product.name2}
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <img
                style={{ width: "100%", height: "400px" }}
                src={`/${product.image}`}
                alt={product.name}
              />
            </div>
            <div className="d-flex justify-content-center">
              <a
                style={{ textDecoration: "none",color:'black' }}
                className="m-0 "
                href={`/tin-tuc-su-kien/${product.code}`}
              >
                Xem thêm &gt; &gt; &gt;
              </a>
            </div>
          </div>
        </a>
      </div>
    );
  };
  return (
    <>
      <div className="mt-3">
        <DataView
          className="container"
          value={NewsData}
          listTemplate={listTemplate}
        />
      </div>
      <Footer />
    </>
  );
}
