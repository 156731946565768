import "./App.css";
import Header from "./components/header/header";
import Home from "./components/homepage/homepage";
import Introduce from "./components/introduce/introduce";
import AllProduct from "./components/products/allProduct";
import GermanyGate from "./components/products/germanygate/germanygate";
import UcGate from "./components/products/ucgate/ucGate";
import AccessoryGate from "./components/products/accessorygate/accessoryGate";
import Recruitment from "./components/tuyendung/tuyendung";
import RecruitmentNVTT from "./components/tuyendung/tuyendungnvtt";
import News from "./components/tintuc/news";
import Contact from "./components/lienhe/lienhe";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import G588RS from "./components/products/germanygate/588RS";
import G589RS from "./components/products/germanygate/589RS";
import G5810RS from "./components/products/germanygate/5810RS";
import G5811RS from "./components/products/germanygate/5811RS";
import G557R from "./components/products/germanygate/557R";
import G558RS from "./components/products/germanygate/558RS";
import G701RS from "./components/products/germanygate/701RS";
import G559R from "./components/products/germanygate/559R";
import G5510R from "./components/products/germanygate/5510R";
import G5511R from "./components/products/germanygate/5511R";
import G515A from "./components/products/germanygate/515A";
import G525A from "./components/products/germanygate/525A";
import G070HE from "./components/products/germanygate/070HE";
import G718HE from "./components/products/germanygate/718HE";
import G518HE from "./components/products/germanygate/518HE";
import G528HE from "./components/products/germanygate/528HE";
import G541HE from "./components/products/germanygate/541HE";
import GHNDa from "./components/products/germanygate/hnda";
import G508HS from "./components/products/germanygate/508HS";
import G5222HS from "./components/products/germanygate/5222HS";
import G5223HS from "./components/products/germanygate/5223HS";
import G5224HP from "./components/products/germanygate/5224HP";
import G5225HP from "./components/products/germanygate/5225HP";
import G5227HP from "./components/products/germanygate/5227HP";
import G5442HL from "./components/products/germanygate/5442HL";
import G5446HL from "./components/products/germanygate/5446HL";
import G71HC from "./components/products/germanygate/71HC";
import G110HD from "./components/products/germanygate/110HD";
import BAP from "./components/products/ucgate/BAP";
import BCOLO from "./components/products/ucgate/BCOLO";
import BMPP from "./components/products/ucgate/BMPP";
import ECO1 from "./components/products/ucgate/ECO1";
import ECO2 from "./components/products/ucgate/ECO2";
import HITECO from "./components/products/accessorygate/hiteco";
import PUMA from "./components/products/accessorygate/puma";
import RITO from "./components/products/accessorygate/rito";
import YH from "./components/products/accessorygate/yh";
import BTNGTDC from "./components/products/accessorygate/botoingoaitocdocao";
import BLD from "./components/products/accessorygate/binhluudien";
import Hinhanhxuongsanxuat from "./components/hinhanhxuongsanxuat/hinhanhxuongsanxuat";
import Banggiacuacuon from "./components/banggiacuacuon/bangiacuacuon";
import Cuacuonkhac from "./components/products/cuacuonkhac/cuacuonkhac";

import Cuacuonluoi from "./components/products/cuacuonkhac/cuacuonluoi";
import Cuacuontrongsuot from "./components/products/cuacuonkhac/cuacuongtrongsuot";
import Khaitruong from "./components/tintuc/khaitruong";
import Top100 from "./components/tintuc/top100";
import Top50 from "./components/tintuc/top50";
import Letrungbay from "./components/tintuc/letrungbay";
import Hiephoi from "./components/tintuc/hiephoi";
import TestMenu from "./components/header/test";
import RecruitmentCSKH from "./components/tuyendung/tuyendungnvcskh";
function App() {
  return (
    <>
      <div className="App">
        {/* <Header /> */}
        <TestMenu />
        <div className="contact">
          <a
            className="row"
            href="https://www.facebook.com/profile.php?id=61556686083050&mibextid=LQQJ4d"
          >
            {" "}
            <img className="mt-1" src="/facebook.png" alt="something" />
          </a>
          <a className="row" href="https://zalo.me/0918045005">
            <img className="mt-1" src="/zalo.png" alt="something" />
          </a>
          <a className="row" href="tel:+84918045005">
            {" "}
            <img className="mt-1" src="/phone.png" alt="something" />
          </a>
        </div>
      </div>
      {/* This is the alias of BrowserRouter i.e. Router */}
      <Router>
        <Routes>
          {/* This route is for home component 
  with exact path "/", in component props 
  we passes the imported component*/}
          <Route exact path="/" element={<Home />} />

          {/* This route is for about component 
  with exact path "/about", in component 
  props we passes the imported component*/}
          <Route path="/gioi-thieu" element={<Introduce />} />
          <Route path="/san-pham" element={<AllProduct />} />
          <Route
            path="/hinh-anh-xuong-san-xuat"
            element={<Hinhanhxuongsanxuat />}
          />
          <Route path="/bang-gia-cua-cuon" element={<Banggiacuacuon />} />
          <Route path="/cua-cuon-duc" element={<GermanyGate />} />
          <Route path="/cua-cuon-uc" element={<UcGate />} />
          <Route path="/phu-kien-cua" element={<AccessoryGate />} />
          <Route path="/cua-cuon-khac" element={<Cuacuonkhac />} />
          <Route path="/tin-tuc-su-kien" element={<News />} />
          <Route
            path="/tin-tuc-su-kien/hitadoor-khai-truong-showroom-tai-binh-duong"
            element={<Khaitruong />}
          />
          <Route path="/tin-tuc-su-kien/hitadoor-dat-top-100-thuong-hieu-chau-a-thai-binh-duong" element={<Top100 />} />
          <Route path="/tin-tuc-su-kien/hitadoor-dat-top-50-thuong-hieu-manh-quoc-gia" element={<Top50 />} />
          <Route
            path="/tin-tuc-su-kien/hitadoor-nha-tai-tro-vang-tai-le-trung-bay-va-ra-mat-hiep-hoi-cua-viet-nam-chi-hoi-thanh-pho-ho-chi-minh"
            element={<Letrungbay />}
          />
          <Route
            path="/tin-tuc-su-kien/hitadoor-tham-gia-trien-lam-trung-bay-cua-cua-hiep-hoi-cua-viet-nam-chi-hoi-thanh-pho-can-tho"
            element={<Hiephoi />}
          />
          <Route path="/tuyen-dung" element={<Recruitment />} />
          <Route
            path="/tuyen-dung/tuyen-dung-nhan-vien-nghien-cuu-va-phat-trien-thi-truong"
            element={<RecruitmentNVTT />}
          />
          <Route
            path="/tuyen-dung/tuyen-dung-nhan-vien-cham-soc-khach-hang"
            element={<RecruitmentCSKH />}
          />
          <Route path="/lien-he" element={<Contact />} />
          <Route path="/san-pham/588RS" element={<G588RS />} />
          <Route path="/san-pham/589RS" element={<G589RS />} />
          <Route path="/san-pham/5810RS" element={<G5810RS />} />
          <Route path="/san-pham/5811RS" element={<G5811RS />} />
          <Route path="/san-pham/557R" element={<G557R />} />
          <Route path="/san-pham/558RS" element={<G558RS />} />
          <Route path="/san-pham/701RS" element={<G701RS />} />
          <Route path="/san-pham/559R" element={<G559R />} />
          <Route path="/san-pham/5510R" element={<G5510R />} />
          <Route path="/san-pham/5511R" element={<G5511R />} />
          <Route path="/san-pham/515A" element={<G515A />} />
          <Route path="/san-pham/525A" element={<G525A />} />
          <Route path="/san-pham/070HE" element={<G070HE />} />
          <Route path="/san-pham/718HE" element={<G718HE />} />
          <Route path="/san-pham/518HE" element={<G518HE />} />
          <Route path="/san-pham/528HE" element={<G528HE />} />
          <Route path="/san-pham/541HE" element={<G541HE />} />
          <Route path="/san-pham/HNDa" element={<GHNDa />} />
          <Route path="/san-pham/508HS" element={<G508HS />} />
          <Route path="/san-pham/5222HS" element={<G5222HS />} />
          <Route path="/san-pham/5223HS" element={<G5223HS />} />
          <Route path="/san-pham/5224HP" element={<G5224HP />} />
          <Route path="/san-pham/5225HP" element={<G5225HP />} />
          <Route path="/san-pham/5227HP" element={<G5227HP />} />
          <Route path="/san-pham/5442HL" element={<G5442HL />} />
          <Route path="/san-pham/5446HL" element={<G5446HL />} />
          <Route path="/san-pham/71HC" element={<G71HC />} />
          <Route path="/san-pham/110HD" element={<G110HD />} />
          <Route path="/san-pham/b_ap" element={<BAP />} />
          <Route path="/san-pham/b_colo" element={<BCOLO />} />
          <Route path="/san-pham/b_mpp" element={<BMPP />} />
          <Route path="/san-pham/eco_1" element={<ECO1 />} />
          <Route path="/san-pham/eco_2" element={<ECO2 />} />
          <Route path="/san-pham/hiteco" element={<HITECO />} />
          <Route path="/san-pham/puma" element={<PUMA />} />
          <Route path="/san-pham/rito" element={<RITO />} />
          <Route path="/san-pham/yh" element={<YH />} />
          <Route path="/san-pham/botoingoaitocdocao" element={<BTNGTDC />} />
          <Route path="/san-pham/binhluudien" element={<BLD />} />
          <Route path="/san-pham/cuacuonluoi" element={<Cuacuonluoi />} />
          <Route
            path="/san-pham/cuacuontrongsuot"
            element={<Cuacuontrongsuot />}
          />
          {/* If any route mismatches the upper 
  route endpoints then, redirect triggers 
  and redirects app to home component with to="/" */}
          {/* <Redirect to="/" /> */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
