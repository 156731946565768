import React from "react";
import "./ucadvantage.css";
export default function UcAdvantage() {
  return (
    <div className="row mt-3">
      <h1 style={{ color: "#1d4794" }}>ƯU ĐIỂM CỬA CUỐN ÚC</h1>
      <div className="col-7 responsive_grid_item">
        <b>An toàn</b>
        <p>&nbsp; &nbsp; &nbsp; &nbsp;
          Cửa cuốn tấm liền có khả năng mở cửa thoát hiểm khi khẩn cấp nhờ bộ
          chốt li hợp được làm bằng dây cable có chốt giật, sử dụng bằng tay để
          đóng/mở cửa. Ngoài ra, cửa còn có thể tự động đảo chiều khi gặp chướng
          ngại vật, sử dụng điện 24VDC an toàn tuyệt đối và đảm bảo an ninh nhờ
          công nghệ chống phá mã mở cửa.
        </p>
        <b>Bền và Nhanh</b>
        <p>&nbsp; &nbsp; &nbsp; &nbsp;
          Độ bền màu của vật liệu thân cửa lên tới trên 25 năm và hệ lò xo trợ
          lực được làm bằng thép C80 có độ bền lên đến 20,000 lần đóng/mở cửa
          mang tới khả năng vận hành bền bỉ vượt thời gian
        </p>
       
        <b>Nhẹ</b>
        <p>&nbsp; &nbsp; &nbsp; &nbsp;
          Vật liệu thân cửa được làm bằng thép hợp kim mạ nhôm kẽm AZ150
          Colorbond của hãng BuleScope Steel – Australia có tỷ trọng thân cửa
          thấp (5kg/m2) và hệ thống lò xo trợ lực luôn cân bằng trọng lực thân
          cửa giúp cửa cuốn thép Tấm liền vận hành nhẹ nhàng và có thể
          dừng ở bất kỳ vị trí nào.
        </p>
        <b>Êm</b>
        <p>&nbsp; &nbsp; &nbsp; &nbsp;
          Thiết kế thân cửa dạng liền tấm, có hệ thống dây Polyglide & gioăng
          cao su chạy dọc thân cửa giúp giảm tối đa ma sát, tích hợp với công
          nghệ truyền động giúp cửa cuốn thép Tấm liền vận hành êm ái, triệt
          tiêu tới 90% tiếng ồn khi đóng, mở cửa.
        </p>
        <b>Thuận tiện</b>
        <p>&nbsp; &nbsp; &nbsp; &nbsp;
          Đây là dòng sản phẩm 2 trong 1: sử dụng hai chức năng vận hành bằng
          điện nhờ bộ tời hoặc bằng tay dễ dàng. Bên cạnh đó, cửa tự động bật
          sáng khi đóng/mở và tự động ngắt sau 3 phút.
        </p>
      </div>
      <div className="col-5 align-self-center responsive_grid_item">
        <img className="advangeImage" src="/ucAdvantage1.jpg" alt="something" />
      </div>
    </div>
  );
}
